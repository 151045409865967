(function ($) {
  $(document).ready(function () {
    if (window.location.href.indexOf('#blue_ocean') !== -1) {
      var win_url = window.location.href;
      var quiz_url =
        win_url.indexOf('#blue_ocean_women') !== -1
          ? '/lcl/blue_ocean/quiz/welcome.html?womensQuiz'
          : win_url.indexOf('#blue_ocean_men') !== -1
          ? '/lcl/blue_ocean/quiz/welcome.html?mensQuiz'
          : '/lcl/blue_ocean/quiz/welcome.html';
      var close_class = win_url.indexOf('#blue_ocean_men') !== -1 ? 'close-link-white' : '';
      var $iframe = $(
        '<a class="close-link ' +
          close_class +
          '"></a><iframe id="blue_ocean_frame" src="' +
          quiz_url +
          '" scrolling="no" horizontalscrolling="no" verticalscrolling="no" marginheight="0" marginwidth="0" frameborder="0" width="776" height="512" style="border: none;"></iframe>'
      );

      generic.overlay.launch({
        content: $iframe,
        cssStyle: {
          padding: '0px',
          height: '511px',
          overflow: 'hidden',
          border: 'none'
        },
        cssClass: close_class,
        includeBackground: true
      });
    }
  });
})(jQuery);
